import { useState } from 'react';
import { DimensionSelect, MetricTypeSelect } from '@/molecules/chartElements';
import { PropertyDimensions } from '@/molecules/chartElements';
import { ChartCard } from '@/organisms/cards';
import { D3Chart, VerticalStackChart } from '@/organisms/charts';
import { EmptyInsights } from '@/organisms/insights';
import { useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { useInsightsContext, useViewModeContext } from '@/shared/providers';
import Stack from '@mui/material/Stack';

const PropertiesStackedBarChart = ({ levelType }) => {
  const [metric, setMetric] = useState('fte');
  const [dimension, setDimension] = useState(
    PropertyDimensions[levelType]?.[0]
  );
  const [sort, setSort] = useState({
    metric: 'fte',
    type: 'DESC',
  });
  const { filter } = useInsightsContext();
  const { showBudget } = useViewModeContext();

  const { chartData, metricTotals } = useAggregateChartData({
    entityType: EntityType.ROLE,
    dimension,
    filter,
    includeStack: true,
    sort,
  });

  const hasProperties = chartData?.length > 1;

  const handleDimensionChange = (dimension) => {
    setDimension(dimension);
  };

  const handleMetricTypeChange = (metric) => {
    setMetric(metric.id);
    setSort({
      ...sort,
      metric: metric.id === 'percentage' ? 'hours' : metric.id,
    });
  };

  return (
    <ChartCard
      title="How roles are categorised"
      ControlsComponent={
        hasProperties && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <DimensionSelect
              dimensions={PropertyDimensions[levelType]}
              label="Property by"
              onChange={handleDimensionChange}
            />
            <MetricTypeSelect
              initialMetric={metric}
              onChange={handleMetricTypeChange}
            />
          </Stack>
        )
      }
    >
      {hasProperties ? (
        <D3Chart height={460} id="property-insights" noScrollY>
          <VerticalStackChart
            initialData={chartData}
            metric={metric}
            metricTotals={metricTotals}
            sort={sort}
          />
        </D3Chart>
      ) : (
        <EmptyInsights message="No properties were found." />
      )}
    </ChartCard>
  );
};

export default PropertiesStackedBarChart;
