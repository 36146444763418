import { MetricProperty } from '@/atoms/enums';
import { Size } from '@/atoms/enums';
import { Paragraph } from '@/atoms/typography';
import { stackColor } from '@/organisms/charts';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';
import { percent } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';
import CenteredSegment from './CenteredSegment';

const SEGMENT_MARGIN = 16;

/**
 * Calculates the size of the stack segments and returns the array of segments.
 *
 * @param {Object}
 *
 * @return {Array}
 */
const buildSegments = ({
  focused,
  metric,
  metricTotals,
  row,
  xScale,
  width,
}) => {
  const stackCount = row.stackData.length;
  const rowWidth = xScale(row?.data?.[metric]) - stackCount * SEGMENT_MARGIN;
  let x = (width - rowWidth) / 2;
  const total = metricTotals[metric];
  const focusedRow = !focused || focused === row.id;

  const segmentData = row.stackData.map((item, index) => {
    const focusedSegment = focusedRow || focused === item.id;
    const colorType = focusedSegment ? item.type : 'INACTIVE';
    const value = item?.data?.[metric] ?? 0;
    const percentage = percent(value, total);
    const color = stackColor(percentage, colorType);
    const segmentMargin = SEGMENT_MARGIN / 2;
    const barWidth = xScale(item.data[metric]);
    const xPosition = x;
    x = xPosition + barWidth + segmentMargin;

    return {
      ...item,
      width: barWidth - segmentMargin,
      color,
      xPosition,
    };
  });

  return segmentData;
};

const CenteredStackBar = ({
  barHeight,
  focused,
  labelWidth,
  metric,
  metricTotals,
  onClick,
  row,
  width,
  xScale,
  yPosition,
}) => {
  const segments = buildSegments({
    focused,
    metric,
    metricTotals,
    row,
    xScale,
    width,
  });

  const firstSegment = segments[0];
  const lastSegment = segments[segments.length - 1];

  const labelPosition = firstSegment?.xPosition - labelWidth;
  const metricPosition = lastSegment?.xPosition + lastSegment?.width;

  const value =
    metric === EntityMetric.PERCENTAGE && row.data[metric] > 0
      ? row.data[metric] / 100
      : row.data[metric];

  const formatOptions = MetricProperty[metric]?.formatOptions;
  const displayValue = formatOptions
    ? format(value, {
        ...formatOptions,
        notation: 'compact',
      })
    : row.data[metric];

  return (
    <g className="centered-bar">
      <foreignObject
        x={labelPosition}
        y={yPosition}
        width={labelWidth}
        height={barHeight}
      >
        <Stack
          direction="row"
          justifyContent="flex-end"
          alignItems="center"
          height={barHeight}
        >
          <Paragraph
            size={Size.MEDIUM}
            overrideStyles={{
              pr: 2,
              mb: 0,
              pt: 0.5,
            }}
          >
            {row.label}
          </Paragraph>
        </Stack>
      </foreignObject>
      <foreignObject
        x={metricPosition}
        y={yPosition}
        width={labelWidth}
        height={barHeight}
      >
        <Stack
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          height={barHeight}
          sx={{ pl: 2 }}
        >
          <div>
            <Paragraph
              size={Size.MEDIUM}
              overrideStyles={{
                textAlign: 'center',
                lineHeight: '1rem',
                fontWeight: 600,
                mb: 0,
                pt: 0.5,
              }}
            >
              {displayValue}
            </Paragraph>
            {metric !== EntityMetric.PERCENTAGE && (
              <Paragraph
                size={Size.X_SMALL}
                overrideStyles={{
                  mb: 0,
                  textAlign: 'center',
                  textTransform: 'uppercase',
                }}
              >
                {metric}
              </Paragraph>
            )}
          </div>
        </Stack>
      </foreignObject>
      {segments.map((segment) => (
        <CenteredSegment
          key={segment.id}
          color={segment.color}
          height={barHeight}
          metric={metric}
          metricTotals={metricTotals}
          onClick={onClick}
          row={segment}
          width={segment.width}
          xPosition={segment.xPosition}
          yPosition={yPosition}
        />
      ))}
    </g>
  );
};

export default CenteredStackBar;
