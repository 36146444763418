import { useEffect, useState } from 'react';
import { Select } from '@/atoms/inputs';
import { useViewModeContext } from '@/shared/providers';
import { EntityMetric } from '@/lib/enums';
import MetricOptions from './MetricOptions';

const METRIC_INPUT_WIDTH = 160;
const DEFAULT_OPTIONS = [
  EntityMetric.PERCENTAGE,
  EntityMetric.HOURS,
  EntityMetric.BUDGET,
  EntityMetric.FTE,
];

const MetricTypeSelect = ({
  options = DEFAULT_OPTIONS,
  initialMetric,
  onChange,
}) => {
  const { showBudget } = useViewModeContext();
  const metricOptions = options
    .filter((id) => showBudget || (!showBudget && id !== EntityMetric.BUDGET))
    .map((id) => MetricOptions[id]);

  const [selected, setSelected] = useState(
    metricOptions.find(({ id }) => id === initialMetric) ?? metricOptions[0]
  );

  const handleChange = (metric) => {
    setSelected(metric);
    onChange?.(metric);
  };

  // This effect resets the metric selection if it's set to budgets and the
  // showBudget permission is removed.
  useEffect(() => {
    if (!showBudget && selected.id === EntityMetric.BUDGET) {
      setSelected(metricOptions[0]);
    }
  }, [showBudget]);

  return (
    <Select
      initialValue={selected}
      label="Metric type"
      onChange={handleChange}
      options={metricOptions}
      optionLabel="label"
      width={METRIC_INPUT_WIDTH}
    />
  );
};

export default MetricTypeSelect;
