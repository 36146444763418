import { Fragment, useEffect, useState } from 'react';
import { Base as BaseColors } from '@/atoms/colors';
import { Divider } from '@/atoms/dividers';
import { Size } from '@/atoms/enums';
import { TextMetricGroup } from '@/molecules/metrics';
import { filterVisibleChartData } from '@/organisms/charts';
import { metricListFromObject } from '@/organisms/insights';
import Box from '@mui/material/Box';
import { EntityMetric } from '@/lib/enums';
import { brand } from '@/lib/theme/tokens/palettes';
import EntityTableRow from './EntityTableRow';

const MetricList = [
  EntityMetric.GROUPS,
  EntityMetric.ROLES,
  EntityMetric.ACTIVITIES,
  EntityMetric.HOURS,
  EntityMetric.HOURS_PERCENTAGE,
  EntityMetric.BUDGET,
];

const defaultVisibleEntities = (entityList) => {
  if (!entityList) {
    return new Set();
  }

  const entityType = entityList[0].type;

  return new Set(
    entityList.filter(({ type }) => type === entityType).map(({ id }) => id)
  );
};

const setDefaultExapanded = (expandedSet) => {
  if (!expandedSet) {
    return new Map();
  }

  return new Map([...expandedSet].map((id) => [id, 0]));
};

const EntitiesTable = ({
  alwaysExpanded,
  focusItem,
  initialData,
  metric,
  metrics,
  metricList = MetricList,
  chartRef,
  selected,
  showBudget,
}) => {
  const [data, setData] = useState([]);
  const tableHeight = chartRef?.current?.clientHeight
    ? chartRef.current.clientHeight - 54
    : 'auto';
  const [expandMap, setExpandMap] = useState(
    setDefaultExapanded(alwaysExpanded)
  );
  const [selectedActivity, setSelectedActivity] = useState();

  // The row click handles the expand and collapse functions of the chart.
  const handleRowClick = (row) => {
    const updatedMap = new Map(expandMap);
    if (row.expanded) {
      // To collapse the selected row and all of it's children we do a partial
      // string match on the id and remove all entries.
      expandMap.entries().forEach(([expandedId]) => {
        if (expandedId.includes(row.id)) {
          updatedMap.delete(expandedId);
        }
      });
    } else {
      updatedMap.set(row.id, 0);
    }

    const filtered = filterVisibleChartData(initialData, updatedMap);

    setExpandMap(filtered.expandMap);
    setData(filtered.data);
    setSelectedActivity(null);
  };

  useEffect(() => {
    if (!initialData) {
      return;
    }

    const expandedMap = alwaysExpanded
      ? setDefaultExapanded(alwaysExpanded)
      : expandMap;

    const filteredData = filterVisibleChartData(initialData, expandedMap);

    setData(filteredData.data);
    setExpandMap(filteredData.expandMap);
  }, [JSON.stringify(initialData)]);

  useEffect(() => {
    if (selectedActivity !== focusItem) {
      handleRowClick({ id: focusItem });
      setSelectedActivity(focusItem);
    }
  }, [focusItem]);

  const totalMetrics = metricListFromObject(metrics, metricList, showBudget);

  return (
    <Box
      sx={{
        boxShadow: `0 0 0 1px ${BaseColors.border.primary}`,
        borderRadius: '1px',
      }}
    >
      <Box p={0.5}>
        <Box
          p={0.5}
          sx={{
            backgroundColor: brand.lightBlue.tints[4],
          }}
        >
          <TextMetricGroup
            boldText
            alignMetrics="flex-end"
            metrics={totalMetrics}
            showDividers={false}
            size={Size.SMALL}
            removePadding
          />
        </Box>
      </Box>
      <Box
        sx={{
          height: tableHeight,
          overflowY: 'auto',
          scrollBehavior: 'smooth',
        }}
      >
        {data.map((entity, index) => (
          <Fragment key={entity.id}>
            <Box m={0.5}>
              <EntityTableRow
                entity={entity}
                focusId={selectedActivity}
                metric={metric}
                onClick={handleRowClick}
              />
            </Box>
            {index !== data.length - 1 && <Divider />}
          </Fragment>
        ))}
      </Box>
    </Box>
  );
};

export default EntitiesTable;
