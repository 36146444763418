import { useEffect, useRef, useState } from 'react';
import { DimensionSelect, MetricTypeSelect } from '@/molecules/chartElements';
import { LayerDimensions } from '@/molecules/chartElements';
import { ChartCard } from '@/organisms/cards';
import { CenteredStackChart, D3Chart } from '@/organisms/charts';
import { EntitiesTable } from '@/organisms/charts';
import { EmptyInsights } from '@/organisms/insights';
import { useAggregateChartData } from '@/organisms/insights';
import { EntityType } from '@/shared/enums';
import { useInsightsContext, useViewModeContext } from '@/shared/providers';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import { EntityMetric } from '@/lib/enums';

const MetricList = [
  EntityMetric.MANAGERS,
  EntityMetric.ROLES,
  EntityMetric.FTE,
  EntityMetric.BUDGET,
];

const calculateMetrics = (chartData, metrics) => {
  const roles = new Set();
  const managers = new Set();

  chartData.forEach((item) => {
    if (item.type === EntityType.MANAGER) {
      managers.add(item.props.uuid);
    }
    if (item.type === EntityType.ROLE) {
      roles.add(item.props.uuid);
    }
  });

  return {
    ...metrics,
    [EntityMetric.MANAGERS]: managers?.size ?? 0,
    [EntityMetric.ROLES]: roles?.size ?? 0,
  };
};

const LayersDistributionChart = ({ levelType }) => {
  const ref = useRef();
  const metricOptions = [
    EntityMetric.SPAN,
    EntityMetric.FTE,
    EntityMetric.BUDGET,
  ];
  const [metric, setMetric] = useState(metricOptions[0]);
  const [dimension, setDimension] = useState(LayerDimensions[levelType]?.[0]);
  const [sort, setSort] = useState({
    metric: metricOptions[0],
    type: 'DESC',
    layers: true,
  });
  const { filter } = useInsightsContext();
  const { showBudget } = useViewModeContext();
  const [focusItem, setFocusItem] = useState();
  const [tableData, setTableData] = useState();
  const [metrics, setMetrics] = useState();

  const { chartData, metricTotals } = useAggregateChartData({
    entityType: EntityType.ROLE,
    excludeSeparators: true,
    dimension,
    filter,
    includeStack: true,
    sort,
  });

  const hasLayers = metricTotals?.count > 0;

  const handleItemFocus = (item) => {
    if (!item?.id || item.id === focusItem) {
      setFocusItem(null);
      setTableData(chartData);
      setMetrics(metricTotals);
      return;
    }
    const tableData = chartData.filter(({ id }) => id.startsWith(item.id));

    setTableData(tableData);
    setMetrics(item.data);
    setFocusItem(item.id);
  };

  const handleDimensionChange = (dimension) => {
    setDimension(dimension);
  };

  const handleMetricTypeChange = (metric) => {
    setMetric(metric.id);
    setSort({
      ...sort,
      metric: metric.id === 'percentage' ? 'hours' : metric.id,
    });
  };

  useEffect(() => {
    if (!focusItem) {
      setTableData(chartData);
      setMetrics(metricTotals);
      return;
    }
    const tableData = chartData.filter(({ id, data }) => {
      if (id === focusItem) {
        setMetrics(data);
      }
      return id.startsWith(focusItem);
    });
    setTableData(tableData);
    setMetrics(focusItem.data);
  }, [JSON.stringify(chartData)]);

  return (
    <ChartCard
      title="How layers are distributed"
      ControlsComponent={
        hasLayers && (
          <Stack direction="row" alignItems="center" spacing={2}>
            <DimensionSelect
              dimensions={LayerDimensions[levelType]}
              label="Layer by"
              onChange={handleDimensionChange}
            />
            <MetricTypeSelect
              options={metricOptions}
              onChange={handleMetricTypeChange}
            />
          </Stack>
        )
      }
    >
      {hasLayers ? (
        <Grid container spacing={2}>
          <Grid item xs={7}>
            <Box ref={ref} height={400}>
              <D3Chart height={400} hideBorder id="layer-insights" noScrollX>
                <CenteredStackChart
                  onClick={handleItemFocus}
                  initialData={chartData}
                  metric={metric}
                  metricTotals={metricTotals}
                  sort={sort}
                />
              </D3Chart>
            </Box>
          </Grid>
          <Grid item xs={5}>
            <EntitiesTable
              chartRef={ref}
              initialData={tableData}
              metrics={metrics}
              metric={metric}
              metricList={MetricList}
              showBudget={showBudget}
            />
          </Grid>
        </Grid>
      ) : (
        <EmptyInsights message="No layers were found." />
      )}
    </ChartCard>
  );
};

export default LayersDistributionChart;
