import { EntityChip, TagChip } from '@/atoms/chips';
import { MetricProperty } from '@/atoms/enums';
import { Size } from '@/atoms/enums';
import { TextMetric } from '@/atoms/metrics';
import { Heading, Paragraph } from '@/atoms/typography';
import { Tooltip } from '@/molecules/modals';
import { EntityType } from '@/shared/enums';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { format } from '@pkg/utils/numbers';
import { EntityMetric } from '@/lib/enums';
import { brand } from '@/lib/theme/tokens/palettes';

const ChartTooltip = ({
  children,
  maxWidth,
  minWidth,
  metric,
  metricTotals,
  position = 'top',
  item,
}) => {
  const { label, data } = item;
  const isTag = item.type === EntityType.TAG;
  const baseMetric =
    metric === EntityMetric.PERCENTAGE ? EntityMetric.HOURS : metric;
  const metricValue = data[baseMetric];
  const entityType =
    item.type === EntityType.PROPERTY_VALUE ? EntityType.PROPERTY : item.type;

  const percentage = metricTotals?.[baseMetric]
    ? format(metricValue / metricTotals[baseMetric], {
        maximumFractionDigits: 2,
        style: 'percent',
      })
    : null;

  const metricLabel =
    metricValue > 1
      ? MetricProperty[baseMetric].label.plural
      : MetricProperty[baseMetric].label.singular;

  const metricFormatOptions = MetricProperty[baseMetric].formatOptions;

  const itemLabel =
    item.type === EntityType.PROPERTY_VALUE
      ? `${item.props?.property}: ${label}`
      : label;

  return (
    <Tooltip
      color="secondary"
      minWidth={minWidth}
      maxWidth={maxWidth}
      position={position}
      title={
        <Stack
          alignItems={'stretch'}
          direction="row"
          justifyContent="space-between"
          spacing={1.5}
          py={0.5}
          width="100%"
        >
          <Box>
            {!isTag && (
              <>
                <EntityChip type={entityType} size={Size.XX_SMALL} />
                <Paragraph
                  size={Size.SMALL}
                  overrideStyles={{ mb: 0, mt: 0.5 }}
                >
                  {itemLabel}
                </Paragraph>
              </>
            )}
            {isTag && (
              <TagChip
                color={item.props?.color}
                name={label}
                expanded
                size={Size.SMALL}
              />
            )}
          </Box>
          <Stack direction="row" alignItems="stretch" spacing={1} pl={2}>
            <TextMetric
              alignItems="center"
              boldText
              label={metricLabel}
              formatOptions={metricFormatOptions}
              size={Size.XX_SMALL}
              value={metricValue}
            />
            {percentage !== null && (
              <Stack
                direction="row"
                alignItems="center"
                sx={{
                  backgroundColor: brand.lightBlue.tints[2],
                  borderRadius: '4px',
                  px: 1,
                }}
              >
                <Heading variant="h6" overrideStyles={{ fontWeight: 600 }}>
                  {percentage}
                </Heading>
              </Stack>
            )}
          </Stack>
        </Stack>
      }
    >
      {children}
    </Tooltip>
  );
};

export default ChartTooltip;
