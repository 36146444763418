import { stackColor } from '@/organisms/charts';
import VerticalSegment from './VerticalSegment';
import { percent } from '@pkg/utils/numbers';

const SEGMENT_MARGIN = 8;

/**
 * Calculates the size of the stack segments and returns the array of segments.
 *
 * @param {Object}
 *
 * @return {Array}
 */
const buildSegments = ({ metric, row, yScale, chartHeight }) => {
  let yPosition = chartHeight;
  const total = row.data[metric];

  const segmentData = row.stackData.map((item, index) => {
    const value = item?.data?.[metric] ?? 0;
    const percentage = percent(value, total);
    const color = stackColor(percentage);
    const barHeight = yScale(item.data[metric]);
    const segmentMargin = index > 0 ? SEGMENT_MARGIN / 2 : 0;
    yPosition = yPosition - barHeight;

    return {
      ...item,
      height: barHeight - segmentMargin,
      color,
      yPosition,
    };
  });

  return segmentData;
};

const VerticalStackBar = ({
  barWidth,
  chartHeight,
  metric,
  metricTotals,
  minBarSize = 8,
  onClick,
  padding = 8,
  row,
  xPosition,
  yScale,
}) => {
  const segments = buildSegments({
    metric,
    row,
    yScale,
    chartHeight,
  });

  return (
    <g
      className="horizontal-bar"
      onClick={onClick}
      style={{ cursor: 'pointer' }}
    >
      {segments.map((segment) => (
        <VerticalSegment
          key={segment.id}
          color={segment.color}
          metric={metric}
          metricTotals={metricTotals}
          row={segment}
          height={segment.height}
          width={barWidth - padding}
          xPosition={xPosition}
          yPosition={segment.yPosition}
        />
      ))}
    </g>
  );
};

export default VerticalStackBar;
