import { EntityType } from '@/shared/enums';

export default function aggregateEntity({
  entities,
  existingMetrics,
  props,
  remainingSet,
  id,
  map,
  nextEntity,
  type,
  uuid,
}) {
  const existing = map.get(id);

  const roleIncrement =
    remainingSet.has(EntityType.ROLE) && entities?.role ? 1 : 0;
  const groupIncrement =
    remainingSet.has(EntityType.GROUP) && entities?.group ? 1 : 0;
  const managerIncrement =
    (remainingSet.has(EntityType.MANAGER) ||
      remainingSet.has(EntityType.ROLE)) &&
    entities?.manager
      ? 1
      : 0;
  const activityIncrement =
    remainingSet.has(EntityType.ACTIVITY) && entities?.activity ? 1 : 0;

  const addSpan = type === EntityType.ROLE;

  if (existing) {
    if (activityIncrement) {
      existing.activitySet.add(entities.activity);
    }
    if (roleIncrement) {
      existing.roleSet.add(entities.role);
    }
    if (groupIncrement) {
      existing.groupSet.add(entities.group);
    }
    if (managerIncrement) {
      existing.managerSet.add(entities.manager);
    }

    const metrics = {
      count: existing.metrics.count + 1,
      activities: existing.activitySet.size,
      groups: existing.groupSet.size,
      managers: existing.managerSet.size,
      roles: existing.roleSet.size,
      span: existing.metrics.span + existingMetrics.span,
      hours: existing.metrics.hours + existingMetrics.hours,
      budget: existing.metrics.budget + existingMetrics.budget,
      fte: existing.metrics.fte + existingMetrics.fte,
    };

    const entity = {
      ...existing,
      metrics,
    };

    if (uuid) {
      entity.entitySet = existing.entitySet.add(uuid);
    }

    map.set(id, entity);
  } else {
    const entityProps = {
      props,
      type,
      metrics: {
        count: 1,
        activities: activityIncrement,
        groups: groupIncrement,
        managers: managerIncrement,
        roles: roleIncrement,
        span: existingMetrics.span,
        hours: existingMetrics.hours,
        budget: existingMetrics.budget,
        fte: existingMetrics.fte,
      },
      activitySet: new Set(activityIncrement ? [entities.activity] : []),
      roleSet: new Set(roleIncrement ? [entities.role] : []),
      groupSet: new Set(groupIncrement ? [entities.group] : []),
      managerSet: new Set(managerIncrement ? [entities.manager] : []),
    };

    if (uuid) {
      entityProps.entitySet = new Set([uuid]);
    }

    if (nextEntity) {
      entityProps.childEntity = nextEntity;
      entityProps[nextEntity] = new Map();
    }

    map.set(id, entityProps);
  }

  if (nextEntity) {
    map = map.get(id)?.[nextEntity];
  }

  return map;
}
