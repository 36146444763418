import { ChartRowType, EntityPluralName } from '@/shared/enums';
import { percent } from '@pkg/utils/numbers';

const addEntityRows = ({
  chartRows,
  entity,
  excludeSeparators,
  includeStack,
  id,
  metrics,
  parentRow,
  stackIndex,
  sort,
}) => {
  const { props, type } = entity;
  const childEntity = entity?.childEntity;

  const percentage = percent(entity.metrics.hours, metrics.hours, 2);
  const ids = id.split(':');
  let updatedIndex = stackIndex;

  const rowMetrics = {
    ...entity.metrics,
    percentage,
  };

  // We collect the parent metrics to be used for calculating relative data
  // in charts.
  const parentMetrics = parentRow?.data;

  const rowData = {
    data: {
      ...rowMetrics,
    },
    parentData: parentRow?.data
      ? {
          ...parentMetrics,
        }
      : null,
    id,
    label: props?.name ?? props?.title ?? props.description,
    props,
    type,
    hasChildren: Boolean(childEntity),
  };

  if (includeStack && ids.length === 1) {
    rowData.stackData = [];
    updatedIndex = chartRows.length;
  }

  if (includeStack && ids.length === 2) {
    rowData.isStackChild = true;
    chartRows[stackIndex].stackData.push(rowData);
  }

  chartRows.push(rowData);

  if (childEntity) {
    [...entity[childEntity].entries()]
      .sort(([, a], [, b]) => {
        if (sort.type === 'ASC') {
          return a.metrics[sort.metric] - b.metrics[sort.metric];
        }

        return b.metrics[sort.metric] - a.metrics[sort.metric];
      })
      .forEach(([childId, childEntity], index) => {
        addEntityRows({
          chartRows,
          entity: childEntity,
          excludeSeparators,
          id: `${id}:${childId}`,
          includeStack,
          parentRow: rowData,
          stackIndex: updatedIndex,
          metrics,
          sort,
        });
      });
  }
};

/**
 * Takes an aggregated list of activities and converts them into rows of data.
 *
 * @param { Map } activities
 * @param { Object } metrics
 * @param { Object } filter
 *
 * @return { Array }
 */
export default function entityChart({
  entities,
  excludeSeparators,
  includeStack,
  sort = { metric: 'hours', type: 'DESC' },
}) {
  const { order, metrics } = entities;

  if (!entities || !order.length) {
    return [];
  }

  // Set the title.
  const chartRows = excludeSeparators
    ? []
    : [
        {
          id: `${order[0]}:TITLE`,
          type: ChartRowType.TITLE,
          entity: order[0],
          label: EntityPluralName[order[0]],
        },
      ];

  [...entities[order[0]].entries()]
    .sort(([, a], [, b]) => {
      // Short circuit if we're sorting layers.
      if (a?.props?.layerNumber) {
        return a.props.layerNumber - b.props.layerNumber;
      }

      if (sort.type === 'ASC') {
        return a.metrics[sort.metric] - b.metrics[sort.metric];
      }

      return b.metrics[sort.metric] - a.metrics[sort.metric];
    })
    .forEach(([id, entity]) => {
      addEntityRows({
        chartRows,
        entity,
        excludeSeparators,
        id,
        includeStack,
        metrics,
        sort,
      });
    });

  return chartRows;
}
