import { EntityType } from '@/shared/enums';
import TagCategory from '@/components/DashboardContainer/enums/TagCategory';
import aggregateEntity from './aggregateEntity';
import createEntityMap from './createEntityMap';
import createMatrixItemMap from './createMatrixItemMap';
import excludeEntity from './excludeEntity';
import getEntityProps from './getEntityProps';
import mapPropertyEntityItems from './mapPropertyEntityItems';
import mapTagEntityItems from './mapTagEntityItems';

const getGroup = ({ entityItem, entityType, role, snapshotEntityMap }) => {
  if (entityType === EntityType.ROLE) {
    return snapshotEntityMap.get(EntityType.GROUP)?.get(role.group_uuid);
  }
  if (entityType === EntityType.ACTIVITY) {
    return role && !role.isUnallocated
      ? snapshotEntityMap.get(EntityType.GROUP).get(role.group_uuid)
      : snapshotEntityMap.get(EntityType.GROUP).get(entityItem.owner_uuid);
  }
};

const getManager = ({ role, snapshotEntityMap }) => {
  const unManaged = {
    title: 'No manager',
    uuid: 'noManager',
    isNotManaged: true,
  };

  const manager = snapshotEntityMap.get(EntityType.ROLE).get(role?.__manager);

  return manager ?? unManaged;
};

const getRole = ({ entityItem, entityType, snapshotEntityMap }) => {
  if (entityType === EntityType.ROLE) {
    return entityItem;
  }
  if (entityType === EntityType.ACTIVITY) {
    return entityItem.owner_type === EntityType.ROLE
      ? snapshotEntityMap.get(EntityType.ROLE).get(entityItem.owner_uuid)
      : {
          title: 'Unallocated',
          uuid: entityItem.owner_uuid,
          isUnallocated: true,
        };
  }
};

const getPerson = ({ role }) => {
  return role?.__person
    ? {
        name: `${role.__person.first_name} ${role.__person.last_name}`,
        uuid: role.__person.uuid,
        avatar: role.__person.avatar,
      }
    : {
        name: 'Unassigned',
        uuid: 'unassigned',
        isUnassigned: true,
      };
};

const getTags = ({ activity, entityType, group, role }) => {
  if (entityType === EntityType.ACTIVITY) {
    return activity?.tags?.length ? activity.tags : [TagCategory.NOT_TAGGED];
  }
  if (entityType === EntityType.ROLE) {
    return role?.tags?.length ? role.tags : [TagCategory.NOT_TAGGED];
  }
  if (entityType === EntityType.GROUP) {
    return group?.tags?.length ? group.tags : [TagCategory.NOT_TAGGED];
  }

  return [TagCategory.NOT_TAGGED];
};

const getProperties = ({ activity, entityType, group, role }) => {
  if (entityType === EntityType.ACTIVITY) {
    return activity?.properties?.length ? activity.properties : [];
  }
  if (entityType === EntityType.ROLE) {
    return role?.properties?.length ? role.properties : [];
  }
  if (entityType === EntityType.GROUP) {
    return group?.properties?.length ? group.properties : [];
  }
};

const getMetric = ({ activityPercentage, entityType, metric, roleMetrics }) => {
  if (entityType === EntityType.ACTIVITY) {
    return activityPercentage && roleMetrics
      ? activityPercentage * roleMetrics[metric]
      : 0;
  }

  return roleMetrics?.[metric] ?? 0;
};

/**
 * Returns a metrics object based on the entity type.
 *
 * @param {Object}
 *
 * @return {Object}
 */
const getMetrics = ({ activity, entityType, role }) => {
  const roleMetrics = role?.__metrics?.self?.total;

  const activityPercentage =
    activity && roleMetrics ? activity.hours / roleMetrics.hours : 0;

  const hours =
    entityType === EntityType.ACTIVITY ? activity.hours : roleMetrics.hours;

  const span = entityType === EntityType.ROLE && role.__manager ? 1 : 0;

  const budget = getMetric({
    activityPercentage,
    entityType,
    metric: 'budget',
    roleMetrics,
  });

  const fte = getMetric({
    activityPercentage,
    entityType,
    metric: 'fte',
    roleMetrics,
  });

  return {
    budget,
    fte,
    hours,
    span: role.__manager ? 1 : 0,
  };
};

export default function mapEntityProperties({
  entityType,
  filter,
  includeUnallocated,
  matrix,
  order,
  propertyMap,
  relationshipMap,
  snapshotEntityMap,
  tagMap,
}) {
  const isManager = entityType === EntityType.MANAGER;
  const metrics = {
    count: 0,
    hours: 0,
    budget: 0,
    fte: 0,
    roles: 0,
    span: 0,
    activities: 0,
    groups: 0,
    people: 0,
  };

  const entityMap = createEntityMap({
    matrix,
    metrics: { ...metrics },
    order,
  });

  const entitySets = new Map([
    [EntityType.ACTIVITY, new Set()],
    [EntityType.LIBRARY_ACTIVITY, new Set()],
    [EntityType.MANAGER, new Set()],
    [EntityType.ROLE, new Set()],
    [EntityType.GROUP, new Set()],
    [EntityType.PERSON, new Set()],
  ]);

  relationshipMap.get(entityType)?.forEach((entityItem) => {
    // Get the relationships of the entities we're mapping.
    const activity = entityType === EntityType.ACTIVITY ? entityItem : null;
    const role = getRole({ entityItem, entityType, snapshotEntityMap });
    const manager = getManager({ role, snapshotEntityMap });
    const group = getGroup({
      entityItem,
      entityType,
      role,
      snapshotEntityMap,
    });
    const person = getPerson({ role });

    // This is where we would filter.
    if (filter) {
      const shouldFilter = excludeEntity({
        activity,
        filter,
        group,
        role,
      });

      if (shouldFilter) {
        return;
      }
    }

    // Add the activity.
    if (activity) {
      entitySets.get(EntityType.ACTIVITY)?.add(activity.uuid);
      entitySets.get(EntityType.LIBRARY_ACTIVITY)?.add(activity.library_uuid);
    }

    // Add the group.
    if (group) {
      entitySets.get(EntityType.GROUP)?.add(group.uuid);
    }

    if (!role.isUnassigned) {
      entitySets.get(EntityType.ROLE)?.add(role.uuid);
    }

    if (role.is_manager) {
      entitySets.get(EntityType.MANAGER)?.add(role.uuid);
    }

    if (!person.isUnassigned) {
      entitySets.get(EntityType.PERSON)?.add(person.uuid);
    }

    // Get the tags for this given entity.
    const tags = getTags({ activity, entityType, group, role });

    const properties = getProperties({ activity, entityType, group, role });

    // Calculate the entity metrics.
    const entityMetrics = getMetrics({
      activity,
      entityType,
      role,
    });

    // Exit early if this role is unallocated and we aren't including
    // unallocated roles;
    if (!includeUnallocated && role.isUnallocated) {
      return;
    }

    // Add the metrics to the entity map.
    if (!role.isUnallocated) {
      entityMap.metrics.budget += entityMetrics.budget;
      entityMap.metrics.count += 1;
      entityMap.metrics.fte += entityMetrics.fte;
      entityMap.metrics.hours += entityMetrics.hours;
      entityMap.metrics.span += entityMetrics.span;
    }

    const entities = {
      role: role?.uuid,
      manager: role?.is_manager ? role?.uuid : null,
      activity: activity?.uuid,
      group: group?.uuid,
    };

    // Here is where we build out the map for this entity.
    let map;

    // This is where we would handle the matrix map.
    if (matrix) {
      map = createMatrixItemMap({
        activity,
        entityMap,
        entityMetrics,
        group,
        order,
        role,
      });
    }

    if (!matrix) {
      map = entityMap[order[0]];
    }

    for (let i = 0; i < order.length; i++) {
      // We need the current and next entity types to assist with recursive
      // ordering.
      const orderEntity = order[i];
      const nextOrderEntity = order[i + 1];
      const remainingSet = new Set(order.slice(i + 1));

      // Handling for tag entities.
      if (orderEntity === EntityType.TAG) {
        map = mapTagEntityItems({
          activity,
          group,
          entityMetrics,
          entities,
          map,
          order,
          orderIndex: i,
          person,
          remainingSet,
          role,
          tags,
          tagMap,
        });
        return;
      }

      if (orderEntity === EntityType.PROPERTY) {
        map = mapPropertyEntityItems({
          activity,
          group,
          entityMetrics,
          entityType,
          entities,
          map,
          order,
          orderIndex: i,
          person,
          remainingSet,
          role,
          properties,
          propertyMap,
        });
        return;
      }

      const props = getEntityProps({
        activity,
        entityType: orderEntity,
        manager,
        group,
        role,
        person,
      });

      if (orderEntity === EntityType.LAYER && props.props?.layerNumber === 0) {
        return;
      }

      if (props) {
        map = aggregateEntity({
          ...props,
          entities,
          existingMetrics: entityMetrics,
          map,
          nextEntity: nextOrderEntity,
          remainingSet,
          type: orderEntity,
        });
      }
    }
  });

  entityMap.metrics.activities = entitySets.get(EntityType.ACTIVITY).size;
  entityMap.metrics.libraryActivities = entitySets.get(
    EntityType.LIBRARY_ACTIVITY
  ).size;
  entityMap.metrics.managers = entitySets.get(EntityType.MANAGER).size;
  entityMap.metrics.roles = entitySets.get(EntityType.ROLE).size;
  entityMap.metrics.groups = entitySets.get(EntityType.GROUP).size;
  entityMap.metrics.people = entitySets.get(EntityType.PERSON).size;

  return entityMap;
}
